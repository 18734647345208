import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 获取运力统计报表
export const getDailyCapacity = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/getDailyCapacity`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取运单统计报表
export const getDailyWaybill = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/getDailyWaybill`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取发票统计表（即发票状态对应的运单）
export const getDailyInvoiceBill = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/getDailyInvoiceBill`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取详细司机报表
export const getDailyDriverDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/getDailyDriverDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取详细车辆报表
export const getDailyCarDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/getDailyCarDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取详细运单报表
export const getDailyWaybillDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/getDailyWaybillDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取详细发票报表
export const getDailyInvoiceDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/getDailyInvoiceDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载每日统计汇总表
export const DownloadSummaryReport = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/DownloadSummaryReport`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 每日统计司机 -- 下载
export const DownloadDriverReport = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/DownloadDriverReport`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 每日统计车辆 -- 下载
export const DownloadCarReport = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/DownloadCarReport`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 每日统计运单 -- 下载
export const DownloadWaybillReport = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/DownloadWaybillReport`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 每日统计发票 -- 下载
export const DownloadInvoiceReport = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/DownloadInvoiceReport`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 临登详情
export const getDailyDriverTaxRegDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DailyReport/getDailyDriverTaxRegDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}